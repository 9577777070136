import React, { useState } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import { SET_OFFERS } from './redux/reducers/offer';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
//app components
import RegistrationBlock from './registration';
import LoginModal from './loginModal';
import Offers from './offers';
import Hero from './hero';
import Content from './content';
import Cart from './cart';
import Recipes from './recipes';
import RecipeContent from './recipes/recipe.js';
import Footer from './footer';
import Spinner from './common/Spinner';
import ErrorToast from './common/ErrorToast';
import PageNotFound from './404';
import Messenger from './messenger';

//styles
import './App.css';

require (`./styles/${process.env.REACT_APP_BRAND_NAME}/styles.scss`);

const history = createBrowserHistory();

function App() {
  const dispatch = useDispatch();

  const {
    config,
    config: {
      retailer: {
        dpn: { id: dpn_id },
      },
      urlParam: { ids: offerIds },
    },
  } = useSelector((state) => state);
  let mpidbyEnv = config.myEnv;
  let [isLoading, updateLoadingState] = useState(true);
  let [isFetching, updateFetchState] = useState(false);
  let [storedClips, updateStoredClips] = useState({ list: [] });
  let [recipeData, setRecipes] = useState([]);
  let [hero, setHero] = useState([]);

  let offers = {
    clipped: [],
    available: [],
    featured: []
  };

  let mediaPId = config.retailer.mpid[mpidbyEnv],
    showLogin = useSelector((state) => state.offer.show_login),
    featuredOffers = config.urlParam.fid || config.urlParam.FID;
   
  function parseOffers(data) {
    let storage = localStorage.getItem('pbyt');
    if (storage) {
      const storedObj = JSON.parse(storage);

      updateStoredClips({ list: storedObj.myIds ? storedObj.myIds : [] });
    }
    let parsed = offers;
    if (data) {
      data.forEach((offer, idx) => {
        const display_start_date = new Date(offer.clipStartDate.iso);
        const display_end_date = new Date(offer.clipEndDate.iso);
        offer.display_start_date = display_start_date;
        offer.display_end_date = display_end_date;
        if (storedClips.list.includes(offer.mdid)) {
          Object.assign(offer, { clipped: true });
        }
        if (
          featuredOffers &&
          featuredOffers.includes(offer.mdid)
        ) {
          parsed.featured.push(offer);
        } else {
          parsed.available.push(offer);
        }
      });
    }
    dispatch(SET_OFFERS({ collections: parsed }));
    return true;
  }
  function fetchOffers() {
    //prevent refetch if already started
    updateFetchState(true);

    let url = 'offers';
    if (config.urlParam.testing) {
      url = '/json/coupons.json';
    }

    let urlObject = {};
    if (offerIds && !featuredOffers) {
      urlObject.ids = offerIds;
    } else if (mediaPId) {
      urlObject.mediaPropertyId = mediaPId;
    }
    url += '?limit=999&' + queryString.stringify(urlObject);
    fetch(config.baseUrl + url, {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': dpn_id,
        Origin: window.location.origin,
        'If-Modified-Since': new Date(),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.length > 0) {
          parseOffers(data);
        }
      })
      .then(() => {
        updateLoadingState(false);
      });
  }
  function fetchRecipes(){
    let recipeInfo = '/json/' + process.env.REACT_APP_BRAND_NAME + '/recipe.json';
    
    fetch( recipeInfo, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    } )
    .then(function(response){
      return response.json();
    })
    .then(function(data) {
      setRecipes(data)
    });
    
  }
  function fetchHero(){
    let heroInfo = '/json/' + process.env.REACT_APP_BRAND_NAME + '/hero.json';
    
    fetch( heroInfo, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    } )
    .then(function(response){
      return response.json();
    })
    .then(function(data) {
      setHero(data)
    });
    
  }
  function ModalBackdrop() {
    if (showLogin) {
      return <div className="modal-backdrop fade show"></div>;
    } else {
      return '';
    }
  }
  function googleAnalytics() {
    var gaId = process.env.REACT_APP_GA;

    var galink = document.createElement('script');
    galink.src = 'https://www.googletagmanager.com/gtag/js?id='+gaId;
    
    var galink2 = document.createElement('script');
    galink2.innerHTML =
      'window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag(\'js\', new Date()); gtag(\'config\', \'' + gaId + '\');'

    if(gaId){
      document.head.appendChild(galink);
      document.head.appendChild(galink2);
    }
    galink.addEventListener('load', function () {
      // document.head.appendChild(galink2);
    });
  }
  function inmarAnalytics() {
    var iaId = process.env.REACT_APP_INMAR_ANALYTICS;

    var ialink = document.createElement('script');
    ialink.innerHTML = 'window._oiqq = window._oiqq || [];  _oiqq.push([\'oiq_addPageLifecycle\', \'' + iaId + '\']); _oiqq.push([\'oiq_doTag\']); (function() { var oiq = document.createElement(\'script\'); oiq.type = \'text/javascript\'; oiq.async = true; oiq.src = \'https://px.owneriq.net/stas/s/br53cg.js\'; var s = document.getElementsByTagName(\'script\')[0]; s.parentNode.insertBefore(oiq, s);})();';
    
    if (iaId){
      document.head.appendChild(ialink);
    }
  }
  function facebookPixel() {
    var fbPixel = process.env.REACT_APP_FB_PIXEL;

    var fblink = document.createElement('script');
    fblink.innerHTML = '!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,\'script\', \'https://connect.facebook.net/en_US/fbevents.js\'); fbq(\'init\', \''+ fbPixel +'\');fbq(\'track\', \'PageView\');';
    
    var fblink2 = document.createElement('noscript');
    fblink2.innerHTML = '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id='+fbPixel+'&ev=PageView&noscript=1"/>';

    if (fbPixel){
      document.head.appendChild(fblink);
      document.head.appendChild(fblink2);
    }
  }
  function facebookValidation() {
    var fbValidationID = process.env.REACT_APP_FB_VALIDATION;

    var fbValidation = document.createElement('meta');
    fbValidation.setAttribute('name',"facebook-domain-verification");
    fbValidation.setAttribute('content',fbValidationID)
    
    if (fbValidationID){
      document.head.appendChild(fbValidation);
    }
  }

  const appClasses = `App ${showLogin ? 'modal-open' : ''}`;

  if (!isFetching) {
    fetchOffers();
    fetchHero();
    googleAnalytics();
    inmarAnalytics();
    facebookPixel();
    facebookValidation();
    if(process.env.REACT_APP_HAS_RECIPES){
      fetchRecipes();
    }
  }
  if (isLoading) {
    return <Spinner />;
  } else {
    const {
      REACT_APP_COLOR_BACKGROUND: background,
      REACT_APP_HAS_CONTENT: hasContent,
      REACT_APP_HAS_CART_ITEMS: hasCart,
      REACT_APP_HAS_RECIPES: hasRecipes,
      REACT_APP_HAS_FOOTER: hasFooter,
      REACT_APP_CHATBOT_PAGEID: hasChat,
    } = process.env;
    const showContent = hasContent === 'true';
    const showCart = hasCart === 'true';
    const showRecipes = hasRecipes === 'true';
    const showFooter = hasFooter === 'true';

    const brand = process.env.REACT_APP_BRAND_NAME;
    const imgPath = '/images/' + brand + '/' + process.env.REACT_APP_BG_IMAGE; 
    
    return (
      <div
        className={('App ', appClasses)}
        id="App"
        style={{
          backgroundColor:background,
          backgroundImage:"url(" + imgPath + ")",
        }}
      >
        <LoginModal />
        <BrowserRouter history={history}>
          <div className={(`App-content container ${process.env.REACT_APP_BRAND_NAME}`)} id="App-content">
            <div id="fb-root"></div>
            <div id="fb-customer-chat" className="fb-customerchat"></div>
            <Switch>
              <Route path={['/', '/home']} exact>
                <Hero hero={hero}/>
                <RegistrationBlock offers={offers} />
                <Offers offers={offers} />
                {showCart && <Cart />} 
                {showRecipes && <Recipes recipes={recipeData}/>}
                {showContent && <Content />}
                {showFooter && <Footer />}
              </Route>
              <Route path="/recipes/:recipeName" >
                <RecipeContent recipes={recipeData} />
              </Route>
              <Route path="*" component={PageNotFound} />
            </Switch>
            <ErrorToast />
          </div>
        </BrowserRouter>
        {hasChat && <Messenger></Messenger>}
        <ModalBackdrop></ModalBackdrop>
      </div>
    );
  }
}
export default App;